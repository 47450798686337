import {
  AuthService,
  ConfigStateService,
  CurrentTenantDto,
  CurrentUserDto,
  SessionStateService,
} from '@abp/ng.core';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Navigate, RouterState } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'cai-nav-items',
  templateUrl: 'nav-items.component.html',
  styleUrls: ['nav-items.component.scss'],
})
export class NavItemsComponent implements OnInit {
  @ViewChild('currentUser', { static: false, read: TemplateRef }) currentUserRef: TemplateRef<any>;
  @Input() smallScreen: boolean;
  @Input() isCollapseNavbarTool: boolean;
  currentUser$: Observable<CurrentUserDto>;
  selectedTenant$: Observable<CurrentTenantDto>;

  constructor(
    private store: Store,
    private authService: AuthService,
    private modalService: NgbModal,
    private sessionStateService: SessionStateService,
    private configState: ConfigStateService,
    private router: Router
  ) {}

  get fullscreen(): boolean {
    return window.innerHeight === screen.height;
  }

  ngOnInit() {
    this.currentUser$ = this.configState.getDeep$('currentUser');
    this.selectedTenant$ = this.sessionStateService.getTenant$();
  }

  logout() {
    this.authService.logout().subscribe(() => {
      this.store.dispatch(
        new Navigate(['/'], null, {
          state: { redirectUrl: this.store.selectSnapshot(RouterState).state.url },
        })
      );
    });
  }

  openVersionModal(content) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'md',
    });
  }

  onClickManageProfile() {
    this.router.navigate(['account/manage']);
  }

  onClickSecurityLogs() {
    this.router.navigate(['account/security-logs']);
  }
}
