import { Environment } from '@abp/ng.core';

const baseUrl = window.location.origin;

export const environment = {
  production: true,
  hmr: false,
  application: {
    baseUrl,
    name: 'Raven',
  },
  oAuthConfig: {
    issuer: '/raven-auth',
    redirectUri: baseUrl,
    clientId: 'Raven_App',
    responseType: 'code',
    skipIssuerCheck: true,
    strictDiscoveryDocumentValidation: false,
    showDebugInformation: true,
    requireHttps: false,
    scope: 'offline_access openid profile role email phone Raven',
  },
  apis: {
    default: {
      url: '',
      rootNamespace: 'Sestek.Raven',
    },
  },
} as Environment;
