import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '@abp/ng.core';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import {
  ConfirmationService,
  HTTP_ERROR_HANDLER,
  ThemeSharedModule,
  ToasterService,
} from '@abp/ng.theme.shared';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { CommercialUiConfigModule } from '@volo/abp.commercial.ng.ui/config';
import { AccountAdminConfigModule } from '@volo/abp.ng.account/admin/config';
import { AccountPublicConfigModule } from '@volo/abp.ng.account/public/config';
import { AuditLoggingConfigModule } from '@volo/abp.ng.audit-logging/config';
import { IdentityServerConfigModule } from '@volo/abp.ng.identity-server/config';
import { IdentityConfigModule } from '@volo/abp.ng.identity/config';
import { LanguageManagementConfigModule } from '@volo/abp.ng.language-management/config';
import { registerLocale } from '@volo/abp.ng.language-management/locale';
import { SaasConfigModule } from '@volo/abp.ng.saas/config';
import { TextTemplateManagementConfigModule } from '@volo/abp.ng.text-template-management/config';
import { HttpErrorComponent, ThemeLeptonModule } from '@volo/abp.ng.theme.lepton';
import { PremiumReportingConfigModule } from '@sestek/premium-reporting/config';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { INTEGRATIONS_ROUTE_PROVIDER } from './integrations/providers/integration-route.provider';
import { BotHubSettingsComponent } from './settings/bothub-settings/bothub-settings.component';
import { PROJECTS_PROJECT_ROUTE_PROVIDER } from './projects/providers/project-route.provider';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { CanDeactivateGuard } from './shared/can-deactivate/can-deactivate.guard';
import { NavItemsComponent } from './shared/nav-items/nav-items.component';
import { LanguagesComponent } from './shared/nav-items/languages/languages.component';
import { SharedModule } from './shared/shared.module';
import { VersionInfoComponent } from './version-info/version-info.component';
import { AGENTS_AGENT_ROUTE_PROVIDER } from './virtual-agents/providers/virtual-agent-route.provider';
import { DASHBOARD_ROUTE_PROVIDER } from './dashboard/providers/dashboard-route.provider';
import { TreeviewModule } from 'ngx-treeview';
import { DropdownTreeviewSelectModule } from './shared/designer/treeview/dropdown-treeview-select.module';
import { CaIntegrationSettingsComponent } from './settings/ca-integration-settings/ca-integration-settings.component';
import {
  AppSettingsService,
  initializeApplicationSettings,
} from './saas-extended/app-settings/app-settings.service';
import { BlockUIModule } from 'ng-block-ui';
import { CONVERSATION_ROUTE_PROVIDER } from './conversation/providers/conversation-route.provider';
import { NotificationModule } from '@sestek/notification';
import { AppNotificationModule } from './notifications/app-notification.module';
import { FooterComponent } from './shared/footer/footer.component';
import { OpenAIIntegrationSettingsComponent } from './settings/openai-integration-settings/openai-integration-settings.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { VALIDATION_ERROR_TEMPLATE } from '@ngx-validate/core';
import { ErrorComponent } from './shared/form-validater';
import { handleHttpErrors } from './shared/http-error-handler/http-error-handler';
import { LogoComponent } from './shared/nav-items/logo/logo.component';
import { PROMPTS_ROUTE_PROVIDER } from './prompts/providers/prompts-route.provider';
import { CustomConfirmationService } from './shared/confirmation/custom-confirmation.service';
import { CustomToasterService } from './shared/toaster/custom-toaster.service';
import { ToastrModule } from 'ngx-toastr';
import { RedactionSettingsComponent } from './settings/redaction-settings/redaction-settings.component';
export let APP_INJECTOR: Injector;

@NgModule({
  declarations: [
    AppComponent,
    NavItemsComponent,
    LanguagesComponent,
    VersionInfoComponent,
    BotHubSettingsComponent,
    CaIntegrationSettingsComponent,
    OpenAIIntegrationSettingsComponent,
    FooterComponent,
    LogoComponent,
    ErrorComponent,
    RedactionSettingsComponent,
  ],
  imports: [
    NgbModule,
    PremiumReportingConfigModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),
    ThemeSharedModule.forRoot({
      httpErrorConfig: {
        errorScreen: {
          component: HttpErrorComponent,
          forWhichErrors: [401, 403, 404, 500],
          hideCloseIcon: true,
        },
      },
    }),
    AccountAdminConfigModule.forRoot(),
    AccountPublicConfigModule.forRoot(),
    IdentityConfigModule.forRoot(),
    LanguageManagementConfigModule.forRoot(),
    SaasConfigModule.forRoot(),
    AuditLoggingConfigModule.forRoot(),
    IdentityServerConfigModule.forRoot(),
    TextTemplateManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    ThemeLeptonModule.forRoot(),
    CommercialUiConfigModule.forRoot(),
    NgxsModule.forRoot([]),
    NotificationModule.forRoot({
      baseUrl: environment.apis.default.url,
      systemDateFormat: 'dd/MM/yyyy',
      showBadge: false,
      playSound: false,
      audioFileUrl: 'assets/sounds/notification-alert.mp3',
    }),
    AppNotificationModule,
    TreeviewModule.forRoot(),
    BlockUIModule.forRoot({
      delayStart: 200,
    }),
    DropdownTreeviewSelectModule,
    SharedModule,
    NgSelectModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    APP_ROUTE_PROVIDER,
    AGENTS_AGENT_ROUTE_PROVIDER,
    PROJECTS_PROJECT_ROUTE_PROVIDER,
    INTEGRATIONS_ROUTE_PROVIDER,
    DASHBOARD_ROUTE_PROVIDER,
    CONVERSATION_ROUTE_PROVIDER,
    PROMPTS_ROUTE_PROVIDER,
    CanDeactivateGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApplicationSettings,
      deps: [AppSettingsService],
      multi: true,
    },
    {
      provide: VALIDATION_ERROR_TEMPLATE,
      useValue: ErrorComponent,
    },
    {
      provide: HTTP_ERROR_HANDLER,
      useValue: handleHttpErrors,
    },
    {
      provide: ConfirmationService,
      useClass: CustomConfirmationService,
    },
    {
      provide: ToasterService,
      useClass: CustomToasterService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    APP_INJECTOR = this.injector;
  }
}
