import { PagedResultDto, RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import {
  SessionDto,
  GetConversationListDto,
  GetConversationListInput,
  SessionDetailDto,
} from './conversation.model';

const PATH = 'api/app/conversations';
@Injectable({
  providedIn: 'root',
})
export class ConversationService {
  apiName = 'Default';

  getSession = (conversationId: string, input: GetConversationListInput) =>
    this.restService.request<void, PagedResultDto<SessionDto>>(
      {
        method: 'GET',
        url: `${PATH}/${conversationId}`,
        params: {
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  getConversationList = (filterValue: GetConversationListDto, input: GetConversationListInput) =>
    this.restService.request<GetConversationListDto, PagedResultDto<SessionDto>>(
      {
        method: 'POST',
        url: `${PATH}`,
        params: {
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
        body: filterValue,
      },
      { apiName: this.apiName }
    );

  getSessionDetail = (conversationSessionId: string) =>
    this.restService.request<void, SessionDetailDto>(
      {
        method: 'GET',
        url: `${PATH}/sessions/${conversationSessionId}`,
      },
      { apiName: this.apiName }
    );

  getMessageAudio = (messageId: string) =>
    this.restService.request<void, Blob>(
      {
        method: 'GET',
        url: `${PATH}/messages/${messageId}/audio`,
        responseType: 'blob',
      },
      { apiName: this.apiName }
    );

  exportList = (filterValue: GetConversationListDto, input: GetConversationListInput) =>
    this.restService.request<GetConversationListDto, any>(
      {
        method: 'POST',
        url: `${PATH}/excel-report-request`,
        params: {
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          timezoneOffset: new Date().getTimezoneOffset(),
        },
        body: filterValue,
      },
      { apiName: this.apiName }
    );

  getExcelReport = (reportId: string) =>
    this.restService.request<any, File>(
      {
        method: 'GET',
        url: `${PATH}/excel-report/${reportId}`,
        responseType: 'blob' as 'json',
      },
      {
        apiName: this.apiName,
        skipHandleError: true,
      }
    );

  getSessionDetailExcelReport = (conversationSessionId: string, getTranslatedMessages: boolean) =>
    this.restService.request<any, File>(
      {
        method: 'GET',
        url: `${PATH}/sessions/${conversationSessionId}/excel-report`,
        params: {
          timezoneOffset: new Date().getTimezoneOffset(),
          getTranslatedMessages: getTranslatedMessages,
        },
        responseType: 'blob' as 'json',
      },
      {
        apiName: this.apiName,
        skipHandleError: true,
      }
    );

  constructor(private restService: RestService) {}
}
