export const DEFAULT_DATE_RANGE_IN_DAYS = 30;
export const MAX_SEARCH_DATE_RANGE_IN_DAYS = 3 * 30;

export const COLORS = [
  '#4dc9f6',
  '#f67019',
  '#f53794',
  '#537bc4',
  '#acc236',
  '#166a8f',
  '#00a950',
  '#58595b',
  '#8549ba',
];

export const SENTIMENT_LEVELS: string[] = [
  'VeryNegative',
  'Negative',
  'Neutral',
  'Positive',
  'VeryPositive',
];

export const SENTIMENT_LEVEL_COLORS: string[] = [
  '#88007A',
  '#B74998',
  '#A5A5A5',
  '#01CED7',
  '#02FBF0',
];
